#home-page {
  ion-toolbar {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    --background: transparent;
    --color: white;
  }

  ion-toolbar ion-back-button,
  ion-toolbar ion-button,
  ion-toolbar ion-menu-button {
    --color: white;
  }

  .stars {
    height: auto;
    color: gold;
    text-align: center;
    &:after {
      content: "★";
      font-size: 1em;
      text-shadow:
        -2em 0,
        -1em 0,
         1em 0,
         2em 0;
    }
  }

  .home-header {
    position: relative;

    width: 100%;
    height: 30%;
  }

  .home-header .home-image {
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    opacity: 0;

    transition: opacity 500ms ease-in-out;
  }

  .home-header .wilson {
    background-image: url("/assets/img/about/2111-wilson.jpg");
  }

  .home-header .madison {
    background-image: url("/assets/img/about/madison.jpg");
  }

  .home-header .austin {
    background-image: url("/assets/img/about/austin.jpg");
  }

  .home-header .chicago {
    background-image: url("/assets/img/about/chicago.jpg");
  }

  .home-header .seattle {
    background-image: url("/assets/img/about/seattle.jpg");
  }

  .home-info {
    position: absolute;
    margin-top: -10px;
    border-radius: 10px;
    background: var(--ion-background-color, #fff);
  }

  .home-info h3 {
    margin-top: 0;
  }

  .home-info .job-prefix {
    margin: 20px 10px 0px;
    opacity: 0.75;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .home-info .job-title {
    margin: -5px 10px 20px;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 0.8em;
    display: none;
  }

  .home-info ion-list {
    padding-top: 0;
  }

  .home-info p {
    line-height: 130%;

    color: var(--ion-color-dark);
  }

  .home-info ion-icon {
    // margin-inline-end: 32px;
  }

  .home-info .home-slides {
    width: 100vw;
    // margin: 0 20px;
    // height: 170px;
  }

  .home-info .home-card img {
    // max-height: 200px;
    // height: 170px;
  }

  .home-info .servicing {
    margin: 0 12px 0 0;
  }

  .home-info .servicing-col {
    padding-top: 0;
    padding-bottom: 0;
  }

  /*
   * Tablet Only
   */
   // Medium devices (tablets, 768px and up)
   @media (min-width: 991px) {
     .home-info .home-slides {
       width: 72vw;
       // margin: 0 20px;
       // height: 170px;
     }
   }

  /*
   * iOS Only
   */

  .ios .home-info {
    --ion-padding: 19px;
  }

  .ios .home-info h3 {
    font-weight: 700;
  }

}
